import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiReqType, ApiResType } from './types/api';
import CamelCaseConverter from '../camelCaseConverter';
import { CheckValidRestaurantParams } from './types/ecoOne';

export default class MMenuApi {
  http: AxiosInstance;

  constructor() {
    this.http = Axios.create({
      baseURL: 'https://api.mmenu.io/v2/',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // Convert snake_case to camelCase
  snakeToCamel = (object: any): any => {
    return CamelCaseConverter.convertKeys('camel', object);
  };

  // convert object to FormData
  toFormData = (object: any): FormData => {
    const formData = new FormData();
    Object.keys(object).forEach((k) => {
      if (object[k] !== undefined) {
        if (['photoCover', 'logo'].includes(k) && typeof object[k] === 'string')
          return;
        if (Array.isArray(object[k])) {
          object[k].forEach((element: string | Blob) => {
            formData.append(`${CamelCaseConverter.toSnake(k)}[]`, element);
          });
        } else {
          formData.append(CamelCaseConverter.toSnake(k), object[k]);
        }
      }
    });
    return formData;
  };

  // Return i18n strings from API error
  handleError = (errResponse: any) => {
    return errResponse?.errors?.map((x: any) => `errors.${x.code}`);
  };

  async request<D>(req: ApiReqType): Promise<ApiResType<D>> {
    try {
      let snakeCasedParams;
      let snakeCasedBody = req.body;
      // for POST and PATCH request, will use `body` instead of `params`
      if (req.params) {
        if (req.method === 'PATCH' || req.method === 'POST') {
          snakeCasedBody = JSON.stringify(req.params);
        } else {
          snakeCasedParams = req.params;
        }
      } else if (req.body && !(req.body instanceof FormData)) {
        snakeCasedBody = JSON.stringify(req.body);
      }
      const config = {
        method: req.method,
        url: req.path,
        params: snakeCasedParams,
        data: snakeCasedBody,
        headers: req.headers,
      } as AxiosRequestConfig;
      const res = await this.http.request(config);
      return this.parseData<D>(res);
    } catch (err) {
      const errors = this.handleError(err.response.data);
      return {
        status: 'failed',
        errors,
      };
    }
  }

  parseData<D>(res: AxiosResponse): ApiResType<D> {
    const parsedData = this.snakeToCamel(res.data);
    return {
      status: 'success',
      result: parsedData,
    };
  }

  async checkValidRestaurant(
    params: CheckValidRestaurantParams,
  ): Promise<ApiResType<any>> {
    const res = await this.request<any>({
      path: `restaurants/getRestaurantInfoForMmenuFood`,
      method: 'POST',
      body: params,
    });
    return res;
  }
}
