import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import style from './style.module.scss';
import InputGroupTag from '../../components/Form/components/InputGroupTag';
import { TitleValidate } from '../../utils/form/validate';
import Button from '../../components/Button';
import { useUserState } from '../User/reducers';
import NavBar from '../../components/NavBar';
import EcoOneApi from '../../services/api/ecoOneApi';
import { useAppDispatch } from '../../store';
import { GetLinkMenuParams } from '../../services/api/types/ecoOne';
import Form from '../../components/Form';
import MMenuApi from '../../services/api/mMenuApi';
import { DialogContext } from '../../components/Dialog/provider';
import RouterPath from '../../constants/RouterPath';

export default function CreateMMenuId() {
  const [userState] = useUserState();
  const { merchantId } = useParams();
  const history = useHistory();
  const dialogContext = useContext(DialogContext);

  const dispatch = useAppDispatch();
  const { userToken } = userState;
  const ecoOneApi = new EcoOneApi(dispatch, userToken);
  const { user } = userState;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [linkMMenu, setLinkMMenu] = useState<string>('');
  const [disabledDeleteButton, setDisabledDeleteButton] = useState<boolean>(
    true,
  );
  const [disabledPublishButton, setDisabledPublishButton] = useState<boolean>(
    true,
  );
  const [errorPreview, setErrorPreview] = useState<boolean>(false);

  const { register, watch, setValue } = useForm<{
    mMenuId: number;
  }>();

  const mMenuId = watch('mMenuId');

  const onClosePress = () => {
    history.push(RouterPath.HOME_PAGE);
  };
  const title = (
    <div className={style.formTitle}>
      <div className={style.closeIcon} onClick={onClosePress} aria-hidden>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <span className={style.formName}>Menu</span>
    </div>
  );

  useEffect(() => {
    getMMenuId();
  }, []);

  const getMMenuId = async () => {
    const res = await ecoOneApi.getMMenuId(merchantId);
    if (res.status === 'success' && res.result.data.mMenuId) {
      setValue('mMenuId', res.result.data.mMenuId);
      getLinkMMenu({ merchantId });
      setDisabledDeleteButton(false);
    } else {
      setDisabledDeleteButton(true);
    }
  };

  const getLinkMMenu = async (payload: GetLinkMenuParams) => {
    const res = await ecoOneApi.getLinkMenu(payload);
    if (res.status === 'success') {
      setLinkMMenu(res.result.data.link);
    } else {
      setLinkMMenu('');
    }
  };

  const checkValidRestaurant = async () => {
    const res = await new MMenuApi().checkValidRestaurant({
      restaurantId: mMenuId,
    });
    if (res.status === 'success') {
      setErrorPreview(false);
      setLinkMMenu(`${process.env.REACT_APP_MMENU_RESTAURANT_URL}/${mMenuId}`);
      setDisabledPublishButton(false);
    } else {
      setErrorPreview(true);
      setLinkMMenu('');
      setDisabledPublishButton(true);
    }
  };

  const updateMMenuId = async (id: number | null) => {
    setLoading(true);
    const res = await ecoOneApi.updateMMenuId(merchantId, id);
    if (res.status === 'success') {
      dialogContext.current?.show({
        message: t('features.addMMenuId.successfully'),
        positiveTitle: t('actions.ok'),
      });
      onClosePress();
    } else {
      dialogContext.current?.show({
        message: t('features.addMMenuId.fail'),
        negativeTitle: t('actions.ok'),
        type: 'warning',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <NavBar user={user} />
      <div className={style.createCardContainer}>
        <div className={style.createCardScroll}>
          <div className={style.createCardSection}>
            {title}
            <span>{t('features.addMMenuId.guide')}</span>
            <Form>
              <div className={style.inputRow}>
                <InputGroupTag
                  id="mMenuId"
                  name="mMenuId"
                  labelText={t('features.addMMenuId.mMenuID')}
                  error={undefined}
                  autoComplete={false}
                  ref={register(TitleValidate)}
                  defaultValue={mMenuId?.toString()}
                />
                <Button
                  title={t('actions.preview')}
                  klassName={style.preview}
                  outlined
                  onClick={() => {
                    checkValidRestaurant();
                  }}
                  disabled={!mMenuId}
                />
              </div>
            </Form>
            <div className={style.buttonsRow}>
              <Button
                title={t('actions.delete')}
                klassName={style.buttonRemove}
                loading={!disabledDeleteButton && loading}
                outlined
                disabled={disabledDeleteButton}
                onClick={() => {
                  updateMMenuId(null);
                }}
              />
              <Button
                title={t('actions.publishCard')}
                klassName={style.buttonPublish}
                loading={!disabledPublishButton && loading}
                disabled={disabledPublishButton}
                onClick={() => {
                  updateMMenuId(mMenuId);
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.iframeContainer}>
          {linkMMenu && (
            <iframe
              src={linkMMenu}
              title="Preview Menu"
              className={style.iframe}
            />
          )}
          {errorPreview && (
            <div className={style.notFoundContainer}>
              <span className="notFoundMmenuid">
                Không tìm thấy thông tin merchant của quý khách. Vui lòng kiểm
                tra lại thông tin merchant
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
