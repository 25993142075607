import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store, AppDispatch } from '../../store';
import { ApiReqType, ApiResType } from './types/api';
import CamelCaseConverter from '../camelCaseConverter';
import userSlice from '../../features/User/reducers';
import setupAxiosInterceptors from './axiosInterceptors';
import {
  GetLinkMenuParams,
  GetLinkMenuResponse,
  GetMMenuIdResponse,
  AssignStaffParamsType,
  ChatSettingsResponse,
} from './types/ecoOne';

export default class EcoOneApi {
  http: AxiosInstance;

  dispatch: AppDispatch;

  store: any;

  // TODO: _ is the old user token. Refactor away!
  /* eslint-disable */
  // @ts-ignore
  constructor(dispatch: AppDispatch, _: any) {
    /* eslint-enable */
    // TODO: Implement version
    this.store = store;
    this.dispatch = dispatch;
    this.http = Axios.create({
      baseURL: process.env.REACT_APP_ECOONE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    setupAxiosInterceptors(userSlice, this.http, dispatch, store);
  }

  // Convert snake_case to camelCase
  snakeToCamel = (object: any): any => {
    return CamelCaseConverter.convertKeys('camel', object);
  };

  // Convert camelCase to snake_case
  camelToSnake = (object: any): any => {
    return CamelCaseConverter.convertKeys('snake', object);
  };

  // convert object to FormData
  toFormData = (object: any): FormData => {
    const formData = new FormData();
    Object.keys(object).forEach((k) => {
      if (object[k] !== undefined) {
        if (['photoCover', 'logo'].includes(k) && typeof object[k] === 'string')
          return;
        if (Array.isArray(object[k])) {
          object[k].forEach((element: string | Blob) => {
            formData.append(`${CamelCaseConverter.toSnake(k)}[]`, element);
          });
        } else {
          formData.append(CamelCaseConverter.toSnake(k), object[k]);
        }
      }
    });
    return formData;
  };

  // Return i18n strings from API error
  handleError = (errResponse: any) => {
    return errResponse?.errors?.map((x: any) => `errors.${x.code}`);
  };

  async request<D>(req: ApiReqType): Promise<ApiResType<D>> {
    try {
      let snakeCasedParams;
      let snakeCasedBody = req.body;
      // for POST and PATCH request, will use `body` instead of `params`
      if (req.params) {
        if (req.method === 'PATCH' || req.method === 'POST') {
          snakeCasedBody = JSON.stringify(this.camelToSnake(req.params));
        } else {
          snakeCasedParams = this.camelToSnake(req.params);
        }
      } else if (req.body && !(req.body instanceof FormData)) {
        snakeCasedBody = JSON.stringify(this.camelToSnake(req.body));
      }
      const config = {
        method: req.method,
        url: req.path,
        params: snakeCasedParams,
        data: snakeCasedBody,
        headers: req.headers,
      } as AxiosRequestConfig;
      const res = await this.http.request(config);
      return this.parseData<D>(res);
    } catch (err) {
      const errors = this.handleError(err.response.data);
      return {
        status: 'failed',
        errors,
      };
    }
  }

  parseData<D>(res: AxiosResponse): ApiResType<D> {
    const parsedData = this.snakeToCamel(res.data);
    return {
      status: 'success',
      result: parsedData,
    };
  }

  async getMMenuId(
    merchantId: number,
  ): Promise<ApiResType<GetMMenuIdResponse>> {
    const res = await this.request<GetMMenuIdResponse>({
      path: `merchants/${merchantId}/m-menu`,
      method: 'GET',
    });
    return res;
  }

  async updateMMenuId(
    merchantId: number,
    mMenuId: number | null,
  ): Promise<ApiResType<any>> {
    const res = await this.request<any>({
      path: `merchants/${merchantId}/m-menu`,
      method: 'PUT',
      body: { mMenuId },
    });
    return res;
  }

  async getLinkMenu(
    params: GetLinkMenuParams,
  ): Promise<ApiResType<GetLinkMenuResponse>> {
    const response = await this.request<GetLinkMenuResponse>({
      path: `merchants/${params.merchantId}/menu-link`,
      method: 'GET',
    });
    return response;
  }

  async getListStaff(
    merchantId: number,
  ): Promise<ApiResType<ChatSettingsResponse>> {
    const res = await this.request<ChatSettingsResponse>({
      path: 'chat/merchant-assignments',
      method: 'GET',
      params: { merchantId },
    });
    return res;
  }

  async assignStaff(params: AssignStaffParamsType): Promise<any> {
    const res = await this.request<any>({
      path: 'chat/merchant-assignments',
      method: 'POST',
      params,
    });
    return res;
  }
}
