import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
import Switch from '@material-ui/core/Switch';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ModalGroup from '../ModalGroup';
import style from './style.module.scss';
import EcoOneApi from '../../services/api/ecoOneApi';
import { useAppDispatch } from '../../store';
import { useUserState } from '../../features/User/reducers';
import { StaffType } from '../../services/api/types/ecoOne';
import Button from '../Button';
import { DialogContext } from '../Dialog/provider';

const i18nPrefix = 'features.chatSettings';

interface PropsType {
  open: boolean;
  handleClose: () => void;
  merchantId: number;
}

export default function ChatSettingsModal(props: PropsType) {
  const { open, handleClose, merchantId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStaffId, setSelectedStaffId] = useState<number | undefined>(0);
  const [staffList, setStaffList] = useState<StaffType[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [userState] = useUserState();
  const { userToken } = userState;
  const ecoOneApi = new EcoOneApi(dispatch, userToken);

  const getListStaff = async () => {
    setLoading(true);
    const res = await ecoOneApi.getListStaff(merchantId);
    if (res.status === 'success') {
      setStaffList(res.result.data.editors);
      setEnabled(!!res.result.data.chatAssignee);
      setSelectedStaffId(res.result.data.chatAssignee?.id);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      getListStaff();
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStaffId(event.target.value as number | undefined);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setEnabled(checked);
    if (checked && staffList?.length > 0) {
      setSelectedStaffId(staffList?.[0]?.id);
    }
  };

  const onPressSave = async () => {
    const res = await ecoOneApi.assignStaff({
      merchantId,
      ...(selectedStaffId && { receiverId: selectedStaffId }),
      type: enabled ? 'active' : 'inactive',
    });
    if ('status' in res && res.status === 'success') {
      dialogContext.current?.show({
        message: t(`${i18nPrefix}.saveSuccessfully`),
        positiveTitle: t('actions.ok'),
      });
    }
    handleClose();
  };

  if (loading) {
    return null;
  }
  return (
    <ModalGroup open={open} handleClose={handleClose}>
      <div className={style.modal}>
        <div className={style.header}>
          <div className={style.modalAction} />
          <div className={style.closeIcon} onClick={handleClose} aria-hidden>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </div>
        </div>
        <div className={style.modalContent}>
          <div className={style.left}>
            <span className={style.title}>{t(`${i18nPrefix}.settings`)}</span>
            <div className={style.menuContainer}>
              <FontAwesomeIcon icon={faComment} size="2x" color="#aaa" />
              <span className={style.menuOption}>
                {t(`${i18nPrefix}.activeChat`)}
              </span>
            </div>
          </div>
          <div className={style.right}>
            <span className={style.title}>{t(`${i18nPrefix}.activeChat`)}</span>
            <div className={style.switchContainer}>
              <span>{t(`${i18nPrefix}.activeChat`)}</span>
              <Switch
                checked={enabled}
                color="primary"
                size="medium"
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <span className={style.note}>
              {t(`${i18nPrefix}.activeChatNote`)}
            </span>
            {enabled && (
              <>
                <div className={style.staffListContainer}>
                  <span className={style.title}>
                    {t(`${i18nPrefix}.chatAssignee`)}
                  </span>
                </div>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedStaffId}
                    label={t(`${i18nPrefix}.chatAssignee`)}
                    onChange={handleChange}
                  >
                    {staffList.map((x) => (
                      <MenuItem value={x.id}>
                        {x.fullName} - {x.phoneNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span className={style.note}>
                  {t(`${i18nPrefix}.chatAssigneeNote`)}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <Button
            title={t(`${i18nPrefix}.save`)}
            onClick={onPressSave}
            disabled={enabled && !selectedStaffId}
          />
        </div>
      </div>
    </ModalGroup>
  );
}
