import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  CardState,
  CardStats,
  CardType,
  FetchCardsPayloadType,
  MerchantType,
  UserStats,
} from '../types';
import {
  createCard,
  deleteCard,
  fetchCard,
  fetchCardsByMerchant,
  fetchCategories,
  fetchEcoparkRegions,
  fetchMerchant,
  getCardStats,
  getMerchants,
  getUserStats,
  updateCard,
  createMerchant,
  updateMerchant,
} from '../actions';
import { RootState, useAppDispatch } from '../../../store';
import {
  CardCreateParamsType,
  CardUpdateParamsType,
  DeleteCardParamsType,
  FetchCardParamsType,
  FetchCardsByMerchantParamsType,
} from '../../../services/api/types/card';
import { CategoryType } from '../../../services/api/types/category';
import { RegionType } from '../../../services/api/types/ecoparkRegion';
import {
  FetchMerchantParamsType,
  MerchantUpdateParamsType,
} from '../../../services/api/types/merchant';
import {
  ApiSuccessListType,
  ApiSuccessType,
} from '../../../services/api/types/api';
import {
  GetCardStatsParams,
  GetMerchantCardsParams,
} from '../../../services/api/types/requestParams';
import removeDuplicateMerchant from '../../../utils/merchant';

const initialState: CardState = {
  merchants: [],
  totalMerchants: 0,
  cards: [],
  totalCards: 0,
  categories: [],
  ecoparkRegions: [],
  userStats: {
    total: 0,
    byAreaSegments: [],
    byAgeSegments: {
      male: [],
      female: [],
    },
  },
  cardStats: {
    totalUniqUserClicked: 0,
    totalPromotionRedemptions: 0,
    redeemedByUserAgeSegments: [],
    redeemedByUserAreaSegments: [],
  },
  merchant: undefined,
};

const cardsSlice = createSlice({
  name: 'merchantCards',
  initialState,
  reducers: {
    setCard(state, action: PayloadAction<any>) {
      state.card = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMerchants.fulfilled,
      (state, action: PayloadAction<ApiSuccessListType<MerchantType[]>>) => {
        if (action.payload.result.data) {
          state.merchants = removeDuplicateMerchant(action.payload.result.data);
          state.totalMerchants = action.payload.result.totalEntries || 0;
        } else {
          state.merchants = [];
          state.totalMerchants = 0;
        }
      },
    );
    builder.addCase(getMerchants.rejected, (state) => {
      state.merchants = [];
      state.totalMerchants = 0;
    });
    builder.addCase(
      fetchCardsByMerchant.fulfilled,
      (state, action: PayloadAction<FetchCardsPayloadType>) => {
        if (action.payload.cards) {
          state.cards = action.payload.cards;
          state.totalCards = action.payload.total;
        } else {
          state.cards = [];
          state.totalCards = 0;
        }
      },
    );
    builder.addCase(fetchCardsByMerchant.rejected, (state) => {
      state.cards = [];
      state.totalCards = 0;
    });
    builder.addCase(
      fetchCategories.fulfilled,
      (state, action: PayloadAction<CategoryType[]>) => {
        if (action.payload) state.categories = action.payload;
        else state.categories = [];
      },
    );
    builder.addCase(
      fetchEcoparkRegions.fulfilled,
      (state, action: PayloadAction<RegionType[]>) => {
        if (action.payload) state.ecoparkRegions = action.payload;
        else state.categories = [];
      },
    );
    builder.addCase(
      fetchCard.fulfilled,
      (state, action: PayloadAction<CardType>) => {
        state.isFetchingCard = false;
        if (action.payload) {
          state.card = action.payload;
        } else state.card = undefined;
      },
    );
    builder.addCase(fetchCard.rejected, (state) => {
      state.isFetchingCard = false;
      state.card = undefined;
    });
    builder.addCase(fetchCard.pending, (state) => {
      state.isFetchingCard = true;
      state.card = undefined;
    });
    builder.addCase(
      fetchMerchant.fulfilled,
      (state, action: PayloadAction<MerchantType>) => {
        state.isFetchingCard = false;
        if (action.payload) state.merchant = action.payload;
        else state.merchant = undefined;
      },
    );
    builder.addCase(fetchMerchant.rejected, (state) => {
      state.isFetchingCard = false;
      state.merchant = undefined;
    });
    builder.addCase(fetchMerchant.pending, (state) => {
      state.isFetchingCard = true;
      state.merchant = undefined;
    });
    builder.addCase(
      deleteCard.fulfilled,
      (state, action: PayloadAction<DeleteCardParamsType>) => {
        if (action.payload)
          state.cards = state.cards.filter(
            (card) => card.id !== action.payload.cardId,
          );
        else state.cards = [];
      },
    );
    builder.addCase(
      updateCard.fulfilled,
      (state, action: PayloadAction<CardType>) => {
        if (action.payload)
          state.cards = state.cards.map((card) => {
            if (card.id === action.payload.id) {
              return { ...card, ...action.payload };
            }
            return card;
          });
        else state.cards = [];
      },
    );
    builder.addCase(
      getCardStats.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<CardStats>>) => {
        state.cardStats = action.payload.result.data;
      },
    );
    builder.addCase(getCardStats.pending, (state) => {
      state.cardStats = undefined;
    });
    builder.addCase(getCardStats.rejected, (state) => {
      state.cardStats = undefined;
    });
    builder.addCase(
      getUserStats.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<UserStats>>) => {
        state.userStats = action.payload.result.data;
      },
    );
  },
});

export function useCardsState() {
  const cardsState = useSelector(
    (state: RootState) => state.cards,
  ) as CardState;
  const dispatch = useAppDispatch();

  const cardsActions = {
    getMerchantCards: (params: GetMerchantCardsParams) =>
      dispatch(getMerchants(params)),
    fetchCardsByMerchant: (params: FetchCardsByMerchantParamsType) =>
      dispatch(fetchCardsByMerchant(params)),
    createCard: (params: CardCreateParamsType) => dispatch(createCard(params)),
    updateCard: (params: CardUpdateParamsType) => dispatch(updateCard(params)),
    updateMerchant: (params: MerchantUpdateParamsType) =>
      dispatch(updateMerchant(params)),
    createMerchant: (params: MerchantUpdateParamsType) =>
      dispatch(createMerchant(params)),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchCard: (params: FetchCardParamsType) => dispatch(fetchCard(params)),
    fetchMerchant: (params: FetchMerchantParamsType) =>
      dispatch(fetchMerchant(params)),
    fetchEcoparkRegions: () => dispatch(fetchEcoparkRegions()),
    deleteCard: (params: DeleteCardParamsType) => dispatch(deleteCard(params)),
    getCardStats: (params: GetCardStatsParams) =>
      dispatch(getCardStats(params)),
    getUserStats: () => dispatch(getUserStats()),
    setCard: (params: any) => {
      dispatch(cardsSlice.actions.setCard(params));
    },
  };
  return [cardsState, cardsActions] as [typeof cardsState, typeof cardsActions];
}

export default cardsSlice;
